<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row class="m-3 w-100">
                <b-col>
                    <div class="mb-3">
                        <h3>{{ $t('enrollment_agrement_form') }}</h3>
                    </div>
                    <div class="font-weight-normal mb-3">
                        {{ $t('enrollment_agrement_form_text') }}
                    </div>
                    <b-row class="d-flex ">
                        <b-col sm="12" md="4" class="mb-3">
                            <b-card cols="4" :title="$t('Your Personel Information')" class="card-size">
                                <b-col>
                                    <b-form-group :label="this.$t('name')">
                                        <b-form-input v-model="form.name" :disabled="true"/>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group :label="this.$t('last_name')">
                                        <b-form-input v-model="form.surname" :disabled="true"/>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <ValidationProvider name="birthdate" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('date_of_birth')"
                                                      class="position-relative">
                                            <v-date-picker
                                                v-model="form.birthdate"
                                                :locale="'EN'"
                                                is-expanded
                                                :masks="masks"
                                                :max-date="new Date()"
                                                :popover="{ 'visibility': 'click' }">
                                                <template v-slot="{ inputValue, inputEvents }">
                                                    <b-input-group>
                                                        <b-form-input
                                                            :disabled="true"
                                                            :readonly="true"
                                                            :value="inputValue"
                                                            v-on="inputEvents"
                                                            :state="errors[0] ? false : (valid ? true : null)">
                                                        </b-form-input>
                                                        <b-input-group-append>
                                                            <b-button
                                                                variant="outline-secondary"
                                                                class="btn-40"
                                                                disabled>
                                                                <i class="ri-calendar-line"></i>
                                                            </b-button>
                                                        </b-input-group-append>
                                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                                 v-html="errors[0]">
                                                        </b-form-invalid-feedback>
                                                    </b-input-group>
                                                </template>
                                            </v-date-picker>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col>
                                    <ValidationProvider name="legal_presence_type" rules="" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('legal_presence_type')">
                                            <parameter-selectbox
                                                :disabled="true"
                                                code="legal_presence_types"
                                                :validateError="errors[0]"
                                                v-model="form.legal_presence_type">
                                            </parameter-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-card>
                        </b-col>
                        <b-col sm="12" md="4" class="mb-3">
                            <b-card cols="4" :title="'Your Mailing U.S.Address'" class="card-size auto-height">
                                <b-col>
                                        <b-form-group :label="$t('address')">
                                            <AddressSearchComponent
                                                :required="!isOnlineAndLegalPresenceTypeControl"
                                                v-model="searchAddress"
                                                @selectedAddress="selected"/>
                                        </b-form-group>
                                </b-col>
                                <b-col>
                                    <ValidationProvider name="contact_city_id"
                                                        :rules="!isOnlineAndLegalPresenceTypeControl?'required':''"
                                                        v-slot="{valid, errors}">
                                        <b-form-group :label="$t('state')">
                                            <city-selectbox
                                                :validateError="errors[0]"
                                                v-model="form.contact_city_id">

                                            </city-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col>
                                    <ValidationProvider name="contact_district_id"
                                                        :rules="!isOnlineAndLegalPresenceTypeControl?'required':''"
                                                        v-slot="{ valid, errors }">
                                        <b-form-group>
                                            <label>{{ $t('city') }}</label>
                                            <district-outline-selectbox v-model="form.contact_district_id"
                                                                        :city_id="form.contact_city_id"
                                                                        :validateError="errors[0]"
                                            />
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col>
                                    <ValidationProvider name="street"
                                                        :rules="!isOnlineAndLegalPresenceTypeControl?'required':''"
                                                        v-slot="{ valid, errors }">
                                        <b-form-group>
                                            <label>{{ $t('street') }}</label>
                                            <b-form-input type="text" v-model="form.contact_address"
                                                          :state="errors[0] ? false : (valid ? true : null)">
                                            </b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col>
                                    <b-row>
                                        <b-col>
                                            <ValidationProvider name="apartment"
                                                                :rules="!isOnlineAndLegalPresenceTypeControl?'required':''"
                                                                v-slot="{ valid, errors }">
                                                <b-form-group>
                                                    <label>{{ $t('apartment') }}</label>
                                                    <b-form-input type="text" v-model="form.apartment"
                                                                  :state="errors[0] ? false : (valid ? true : null)">
                                                    </b-form-input>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col>
                                            <ValidationProvider name="address_zip_code"
                                                                :rules="!isOnlineAndLegalPresenceTypeControl?'required':''"
                                                                v-slot="{valid, errors}">
                                                <b-form-group :label="$t('zip_code')">
                                                    <b-form-input
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        v-model="form.address_zip_code">
                                                    </b-form-input>
                                                    <b-form-invalid-feedback v-if="errors[0]"
                                                                             v-html="errors[0]">
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                </b-col>

                            </b-card>
                        </b-col>
                        <b-col sm="12" md="4" class="mb-3">
                            <b-card cols="4" :title="'Your Home Address'" class="card-size">
                                <b-col>
                                    <ValidationProvider name="address_country_id"
                                                        :rules="isOnlineAndLegalPresenceTypeControl?'required':''"
                                                        v-slot="{valid, errors}">
                                        <b-form-group :label="$t('country')">
                                            <country-selectbox
                                                :validateError="errors[0]"
                                                v-model="form.address_country_id">
                                            </country-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col>
                                    <ValidationProvider name="address"
                                                        :rules="isOnlineAndLegalPresenceTypeControl?'required':''"
                                                        v-slot="{valid, errors}">
                                        <b-form-group :label="$t('address')">
                                            <b-form-input
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                v-model="form.address">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                                     v-html="errors[0]">
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                            </b-card>
                        </b-col>
                        <b-col sm="12" md="4" class="mb-3">
                            <b-card cols="4" :title="'Contact Information'" class="card-size">
                                <b-col>
                                    <ValidationProvider name="email" rules="required|email" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('email')">
                                            <b-form-input type="text"
                                                          v-model="form.email"
                                                          :state="errors[0] ? false : (valid ? true : null)">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                                     :v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col>
                                    <ValidationProvider name="gsmNo" rules="required" v-slot="{valid, errors}">
                                        <b-form-group
                                            :label="isOnlineAndLegalPresenceTypeControl?$t('phone_number'):$t('gsm_no2')">
                                            <PhoneNumberInput
                                                v-model="mobile_tel"
                                                :default-country-code="mobileTelCodeFlag"
                                                :validate-error="errors[0]"
                                                @update="mobileTelUpdated"
                                                :no-flag="!isOnlineAndLegalPresenceTypeControl"
                                            ></PhoneNumberInput>
                                        </b-form-group>

                                    </ValidationProvider>
                                </b-col>
                                <b-col>
                                    <ValidationProvider name="home_tel" rules="" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('other_phone')">
                                            <PhoneNumberInput
                                                ref="phoneInput"
                                                v-model="form.home_tel"
                                                @update="otherPhoneUpdate"
                                                :default-country-code="homeTelCodeFlag"
                                                :validate-error="errors[0]"
                                            ></PhoneNumberInput>


                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-card>
                        </b-col>
                        <b-col sm="12" md="4" class="mb-3">
                            <b-card cols="4" :title="'Emergency Contact Information'" class="card-size">
                                <b-col>
                                    <ValidationProvider name="emergency_person_name" rules="required"
                                                        v-slot="{valid, errors}">
                                            <b-form-group :label="$t('name2')">
                                                <b-form-input v-model="form.emergency_person_name"/>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col>
                                    <ValidationProvider name="emergency_person_relation" rules="required"
                                                        v-slot="{valid, errors}">
                                        <b-form-group :label="$t('relationship_to_student')">
                                            <b-form-input
                                                v-model="form.emergency_person_relation"
                                                :state="errors[0] ? false : (valid ? true : null)">
                                            </b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                                     v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col>
                                    <ValidationProvider name="emergency_person_phone" rules="" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('emergency_person_phone')">
                                            <PhoneNumberInput
                                                v-model="form.emergency_person_phone"
                                                @update="emergencyPhoneUpdate"
                                                :default-country-code="emergencyCodeFlag"
                                                :validate-error="errors[0]"
                                            ></PhoneNumberInput>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                            </b-card>
                        </b-col>


                        <div class="col-12 d-flex justify-content-between align-items-center">
                            <ValidationProvider name="status" rules="required"
                                                v-slot="{valid, errors}">
                                <div class="mr-3">
                                    <b-form-checkbox
                                        id="checkbox-1"
                                        v-model="form.status"
                                        name="checkbox-1"
                                        value="accepted"
                                        unchecked-value="not_accepted"
                                    >
                                        I confirm that the information given in this form is true, complete and accurate
                                    </b-form-checkbox>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </div>
                            </ValidationProvider>

                            <b-button type="submit" @click="next" variant="primary" class="mr-3">{{
                                    $t('next')
                                }}
                            </b-button>
                        </div>
                    </b-row>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
import CitySelectbox from "@/components/interactive-fields/CitySelectbox.vue";
import EnrollmentAgreementForm from "@/services/EnrollmentAgreementForm";
import {mapGetters} from "vuex";
import DistrictOutlineSelectbox from "@/components/interactive-fields/DistrictOutlineSelectbox.vue";
import CountryService from "@/services/CountryService";
import DistrictOutlineService from "@/services/DistrictOutlineService";
import CityService from "@/services/CityService";
import countryCodeInputData from "@/modules/students/pages/student/contactInformation/countryCodeInputData"
import PhoneNumberInput from "@/components/elements/PhoneNumberInput.vue";
import AddressSearchComponent from "@/components/interactive-fields/AddressSearchComponent.vue";


export default {
    components: {AddressSearchComponent, PhoneNumberInput, DistrictOutlineSelectbox, CitySelectbox},
    data() {
        return {
            phoneData: {
                formattedNumber: '',
                countryCode: '',
                nationalNumber: '',
                fullEvent: {}
            },
            registration_type_text: null,
            iso2: 'US',
            form: {
                address_country_name: null,
                mobile_tel_country_code: 1,
                home_tel_country_code: null,
            },
            mobile_tel: null,
            countryCodeInputData,
            masks: {
                input: "DD-MM-YYYY",
            },
            users: null,
            code: null,
            otherCode: null,
            emergencyCode: null,
            legalPresenceType: null,
            searchAddress: null,
        }
    },
    computed: {
        ...mapGetters({
            formData: "enrollmentAgreementForm/formData"
        }),
        isOnlineAndLegalPresenceTypeControl() {
            //return ['Online','F-1 Exchange','Exchange'].includes(this.registration_type_text)
            return true;
        },
        emergencyCodeFlag(){
            return this.getCountryCodeByCallingCode(this.formData.emergency_person_phone_country_code)
        },
        homeTelCodeFlag(){
            return this.getCountryCodeByCallingCode(this.formData.home_tel_country_code)
        },
        mobileTelCodeFlag(){
            return this.getCountryCodeByCallingCode(this.formData.mobile_tel_country_code)
        }
    },
    watch: {
        'form.address_country_id'(value) {
            if (value != null) {
                this.selectedCountryName(value)
            }
        },
        'form.contact_district_id'(value) {
            if (value != null) {
                this.selectedDistrict(value)
            }
        },
        'form.contact_city_id'(value) {
            if (value != null) {
                this.selectedCity(value)
            }
        },
    },
    created() {
        this.getData()
    },
    methods: {
        selectedCountryName(value) {
            const config = {
                params: {
                    sort: ('name'),
                    limit: -1
                }
            };
            CountryService.getAll(config)
                .then(response => {
                    const data = response.data.data;
                    const selectedData = data.find(item => item.id === value);
                    this.form.address_country_name = selectedData.name;
                }).catch(err => {
                this.showErrors(err)
            })
        },
        selectedDistrict(value) {
            DistrictOutlineService.getDistrict(this.form.contact_city_id)
                .then(response => {
                    const data = response.data.data;
                    const selectedData = data.find(item => item.id === value);
                    this.form.contact_district_name = selectedData.name;
                }).catch(err => {
                this.showErrors(err)
            })
        },
        selectedCity(value) {
            const config = {
                params: {
                    limit: -1
                },
            };
            this.options = [];
            CityService.getOptions(config)
                .then(response => {
                    const data = response.data.data;
                    const selectedData = data.find(item => item.id === value);
                    this.form.contact_city_name = selectedData.name;
                })
        },
        async getData() {
            let user = await JSON.parse(localStorage.getItem('user'));
            const response = await EnrollmentAgreementForm.getStudentInformation(user.id)
            this.registration_type_text =response.data.data.registration_type_text
            this.legalPresenceType =response.data.data.legal_presence_type
            await this.$store.dispatch('enrollmentAgreementForm/saveFormData', response.data.data);
            this.form = {...this.formData}
            this.mobile_tel = (this.formData.mobile_tel_country_code ?? '') + this.formData.mobile_tel
        },
        async next() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                if (this.otherCode != null) {
                    this.form.home_tel_country_code = '+' + this.otherCode.countryCallingCode
                }
                if (this.emergencyCode != null) {
                    this.form.emergency_person_phone_country_code = '+' + this.emergencyCode.countryCallingCode
                }
                if (this.code != null) {
                    this.form.mobile_tel_country_code = '+' + this.code.countryCallingCode
                    this.form.countryCodeForm = this.code.countryCode
                } else {
                    this.form.mobile_tel_country_code = 1
                    this.form.countryCodeForm = 'US'
                }
                await this.$store.dispatch('enrollmentAgreementForm/saveFormData', this.form);
                await this.$emit('next', 1);
            }
        },
        mobileTelUpdated(event) {
            this.code = event
            this.form.mobile_tel = this.code.nationalNumber;
            this.form.mobile_tel_country_code = '+' + this.code.countryCallingCode
        },
        otherPhoneUpdate(event) {
            this.otherCode = event
            this.form.home_tel = this.otherCode.nationalNumber;
            this.form.home_tel_country_code = '+' + this.otherCode.countryCallingCode
        },
        emergencyPhoneUpdate(event) {
            this.emergencyCode = event
            this.form.emergency_person_phone = this.emergencyCode.nationalNumber;
            this.form.emergency_person_phone_country_code = '+' + this.emergencyCode.countryCallingCode
        },
        selected(event) {
            this.form.contact_city_id = event.city_id
            this.form.contact_district_id = event.district_id
            this.form.address_zip_code = event.zip_code
            this.form.contact_address = event.street_name
            this.form.apartment = event.street_number
        },
    }
}
</script>
<style scoped>
.card-size {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 600px;
    height: auto;
    padding: 1rem;
    box-sizing: border-box;
}

.auto-height {
    height: auto !important; /* Gerekirse zorunlu olarak otomatik yükseklik sağlar */
}
</style>
