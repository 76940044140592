<template>
  <div>
    <b-row class="mt-3">
      <b-col cols="12" class="d-flex justify-content-center align-item-center mb-5">
        <div class="logo-wrapper ">
          <img src="../../../assets/img/BAU_Logo.png" height="130"/>
        </div>
      </b-col>
      <b-col class="d-flex justify-content-center align-item-center mb-5">
        <h1 class="text-center">{{ $t('bahcesehir_university') }}</h1>
      </b-col>
      <b-col cols="12" class="d-flex justify-content-center align-item-center">
      </b-col>
      <b-col>
        <template>
          <page1 v-if="currentPage==1" @next="next($event)" @back="next($event)"/>
        </template>
        <template>
          <page2 v-if="currentPage==2" @next="next($event)" @back="next($event)"/>
        </template>
        <template>
          <page3 v-if="currentPage==3" @next="next($event)" @back="next($event)"/>
        </template>
        <template>
          <page4 v-if="currentPage==4" @next="next($event)" @back="next($event)"/>
        </template>
        <template>
          <page5 v-if="currentPage==5" @next="next($event)" @back="next($event)"/>
        </template>
        <template>
          <page6 v-if="currentPage==6" @next="next($event)" @back="next($event)"/>
        </template>
        <template>
          <page7 v-if="currentPage==7" @next="next($event)" @back="next($event)"/>
        </template>
        <template>
          <page8 v-if="currentPage==8" @next="next($event)" @back="next($event)"/>
        </template>
        <template>
          <page9 v-if="currentPage==9" @next="next($event)" @back="next($event)"/>
        </template>
        <template>
          <page10 v-if="currentPage==10" @next="next($event)" @back="next($event)"/>
        </template>


      </b-col>
    </b-row>
    <CommonModal size="lg" ref="sendForm">
      <template v-slot:CommonModalTitle>
        <div>{{ $t('Enrollment Agreement Form') }}</div>
      </template>
      <template v-slot:CommonModalContent>
        <div>
          <sendForm v-if="formName=='send-form'" @sendSuccessForm="successForm" @errorSmsCode="errorSmsCode"></sendForm>
        </div>
      </template>
    </CommonModal>
    <CommonModal size="md" ref="phone_verification_code_modal">
      <template v-slot:CommonModalTitle>
          Please verify your <b v-if="studentData && !isOnlineAndLegalPresenceTypeControl">U.S.</b> phone number before proceeding to the next page
      </template>
      <template v-slot:CommonModalContent>
        <div>
          <VerifyCode v-if="formName=='phoneVerificationCodeModal'" @closeVerificationModal="closeModal"
                      @showVerifyModal="sendSms($event)"/>
        </div>
      </template>
    </CommonModal>
    <CommonModal ref="updatePhoneModal" size="md" :onHideOnlyX="true">
      <template v-slot:CommonModalTitle>
        {{ $t('verification').toUpper() }}
      </template>
      <template v-slot:CommonModalContent>
        <div class="mb-3">
          <ValidationObserver ref="formSmsVerify">
            <ValidationProvider name="sms_code" rules="required" v-slot="{ valid, errors }">
              <sms-input v-model="smsCode" :timerFrom="smsTimer" @timerExpired="timerExpired" :clearInput="clearInput"
                         ref="smsInput"/>
            </ValidationProvider>
          </ValidationObserver>
        </div>
        <div class="mt-3">
          <b-button type="button" variant="primary" size="lg" block @click="smsVerify"
                    :disabled="smsTimer == 0" :loading="isLoading">
            {{ $t('verify').toUpper() }}
          </b-button>
        </div>
      </template>
    </CommonModal>
  </div>
</template>
<script>

import Page1 from "@/modules/enrollmentForm/pages/page1.vue";
import Page2 from "@/modules/enrollmentForm/pages/page2.vue";
import Page3 from "@/modules/enrollmentForm/pages/page3.vue";
import Page4 from "@/modules/enrollmentForm/pages/page4.vue";
import Page5 from "@/modules/enrollmentForm/pages/page5.vue";
import Page6 from "@/modules/enrollmentForm/pages/page6.vue";
import Page7 from "@/modules/enrollmentForm/pages/page7.vue";
import Page8 from "@/modules/enrollmentForm/pages/page8.vue";
import Page9 from "@/modules/enrollmentForm/pages/page9.vue";
import Page10 from "@/modules/enrollmentForm/pages/page10.vue";
import sendForm from "@/modules/enrollmentForm/pages/sendForm.vue";
import VerifyCode from "@/modules/enrollmentForm/pages/verifyCode.vue";
import SmsInput from "@/components/elements/SmsInput.vue";

export default {
  components: {SmsInput, VerifyCode, Page2, Page1, Page3, Page4, Page5, Page6, Page7, Page8, Page9, Page10, sendForm},
  data() {
    return {
      rows: 10,
      perPage: 1,
      currentPage: 1,
      masks: {
        input: "DD-MM-YYYY",
      },
      users: null,
      clearInput: false,
      formName: null,
      showModal: false,
      smsTimer: null,
      phone: null,
      isLoading: false,
      smsCode: null,
      studentData: null
    }
  },
  metaInfo() {
    return {
      title: this.$t("enrollment_agrement_form"),
    };
  },
  watch: {
    showModal(value) {
      if (value) {
        this.phoneVerificationCodeModal()
      }
    },
    // currentPage() {
    //   // this.openHtmlModal()
    //   this.phoneVerificationCodeModal()
    // }
  },
    computed: {
        isOnlineAndLegalPresenceTypeControl() {
            //return ['Online','F-1 Exchange','Exchange'].includes(this.studentData.registration_type_text)
            return true;
        },
    },
  methods: {
    timerExpired() {
      this.smsTimer = 0;
    },
    sendSms(data) {
      this.phone = data.mobile_tel
      this.studentData = data.student_data
      this.$refs.updatePhoneModal.$refs.commonModal.show()
      this.smsTimer = 120;
    },
    closeModal() {
      this.$refs.phone_verification_code_modal.$refs.commonModal.hide();
    },
    next(event) {
      if (this.currentPage < 11)
        if (this.currentPage < 10) {
          this.currentPage += event;
        } else {
          this.showModal = true
        }
    },
    async openHtmlModal() {
      this.formName = 'send-form'
      await this.$refs.sendForm.$refs.commonModal.show();
    },
    phoneVerificationCodeModal() {
      this.formName = 'phoneVerificationCodeModal'
      this.$refs.phone_verification_code_modal.$refs.commonModal.show();
    },
    successForm() {
      this.formName = null
      this.$refs.sendForm.$refs.commonModal.hide();
    },
    async smsVerify() {
      await this.$store.dispatch('enrollmentAgreementForm/deleteFormData');
      await this.$store.dispatch('enrollmentAgreementForm/saveFormData',
          {
            ...this.studentData,
            verification_code: this.smsCode
          });
      this.openHtmlModal()
      this.$refs.updatePhoneModal.$refs.commonModal.hide()
      this.smsCode = null
      this.clearInput = true

    },
    errorSmsCode() {
      this.phoneVerificationCodeModal()
      this.$refs.sendForm.$refs.commonModal.hide();
     this.timerExpired()

    }
  }
}
</script>
<style>
.b-pagination .page-item:first-child,
.b-pagination .page-item:last-child {
  display: none;
}


</style>
