<template>
  <div>
    <ValidationObserver ref="formModalValidate">
      <ValidationProvider name="gsmNo" rules="required" v-slot="{valid, errors}">
        <b-form-group :label="isOnlineAndLegalPresenceTypeControl?$t('phone_number'):$t('gsm_no2')">
            <PhoneNumberInput
                v-model="mobile_tel"
                :validate-error="errors[0]"
                :defaultCountryCode="studentData.countryCodeForm"
                :no-flag="!isOnlineAndLegalPresenceTypeControl"
                @update="mobileTelUpdated"
            ></PhoneNumberInput>
        </b-form-group>
      </ValidationProvider>
      <b-button type="submit" @click="sendVerificationSms" variant="primary" class="mr-3">{{
          $t('next')
        }}
      </b-button>
    </ValidationObserver>
  </div>
</template>
<script>
import EnrollmentAgreementForm from "@/services/EnrollmentAgreementForm";
import PhoneNumberInput from "@/components/elements/PhoneNumberInput.vue";
export default {
  data() {
    return {
      phone: null,
      smsTimer: null,
      smsCode: null,
      isLoading: false,
      iso2:null,
        mobile_tel:null,
        form:null
    }

  },
  components: {PhoneNumberInput},
  computed: {
      studentData() {
       return JSON.parse(localStorage.getItem('enrollmentFormData'))
      },
      isOnlineAndLegalPresenceTypeControl() {
          //return ['Online','F-1 Exchange','Exchange'].includes(this.studentData.registration_type_text)
          return true;
      }
  },

  methods: {
    async sendVerificationSms() {
      const config = {
        mobile_tel: this.mobile_tel
      }
      const res = await EnrollmentAgreementForm.verifyMobileTel(config)
      if (res.data.success) {
        this.$emit('closeVerificationModal', true)
        this.$emit('showVerifyModal', {
          mobile_tel: this.mobile_tel,
          student_data: this.form
        })
      }
    },
    async clearForm() {
      await this.$store.dispatch('enrollmentAgreementForm/deleteFormData');
    },
      mobileTelUpdated(event){
          this.form.mobile_tel=event.nationalNumber;
          this.form.mobile_tel_country_code='+'+event.countryCallingCode
      }
  },
    created() {
        this.form = {...this.studentData}
        this.mobile_tel=this.form.mobile_tel_country_code+this.form.mobile_tel
    }
};
</script>
<style scoped>
@media print {
  body {
    -webkit-print-color-adjust: exact !important;
  }

  .no-button {
    display: none;
  }
}
</style>

